import {
  ListCell,
  ListRow,
  List,
  Button,
  ListDrawer,
} from '@administrate/piston-ux';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import inject from '../../../containers/inject';
import { STORES } from '../../../constants';
import CartItemDescription from './CartItemDescription';
import CartItemPrice from './CartItemPrice';
import CartItemQuantity from './CartItemQuantity';

import { formatDateLong } from '../../../utils/formatDateTime';

export const CART_ROW_GRID_COLUMNS = '5fr 1fr 1fr 1fr 0.5fr 0.5fr';

const { STORE_CART } = STORES;

const CartRow = ({
  row,
  hideEditButton,
  showPlacesRemaining,
  [STORE_CART]: { isLoading, changeSeats, removeFromCart, openEventPicker },
  t,
}) => {
  const [expanded, setExpanded] = useState(false);

  const showEditButton = !hideEditButton && !row.isPath && !row.isGiftVoucher;

  return (
    <ListRow>
      <ListRow gridColumns={CART_ROW_GRID_COLUMNS}>
        <ListCell
          extraClass={
            row.isGiftVoucher ? 'cart-row-item voucher-item' : 'cart-row-item'
          }
        >
          <CartItemDescription
            row={row}
            showPlacesRemaining={showPlacesRemaining}
          >
            {!!row.pathId && row.objectives.length !== 0 && (
              <>
                <Button
                  type="suppressed"
                  label={t(
                    expanded ? 'weblink:hideDates' : 'weblink:showDates',
                  )}
                  onClick={() => setExpanded(!expanded)}
                />{' '}
                <span
                  className={`glyphicon ${
                    expanded
                      ? 'glyphicon-chevron-down'
                      : 'glyphicon-chevron-right'
                  }`}
                />
              </>
            )}
          </CartItemDescription>
        </ListCell>
        <ListCell extraClass="cart-row-price">
          <>
            <CartItemPrice amount={row.unitAmount} />
            <div>
              {row.priceLevel && row.priceLevel.name !== 'Normal'
                ? row.priceLevel.name
                : ''}
            </div>
          </>
        </ListCell>
        <ListCell extraClass="cart-row-quantity">
          <CartItemQuantity
            row={row}
            disabled={isLoading || row.isGiftVoucher}
            onChange={value => {
              changeSeats({
                row,
                value,
              });
            }}
          />
        </ListCell>
        <ListCell extraClass="cart-row-total">
          <CartItemPrice amount={row.subTotalAmount} />
        </ListCell>
        <ListCell extraClass="cart-row-edit">
          {showEditButton && (
            <EditEventButton
              t={t}
              onClick={() =>
                openEventPicker({
                  eventPickerItem: row,
                })
              }
            />
          )}
        </ListCell>
        <ListCell extraClass="cart-row-remove">
          <RemoveItemButton t={t} onClick={() => removeFromCart(row.id)} />
        </ListCell>
      </ListRow>
      {expanded && (
        <ListDrawer open>
          <List>
            <LearningPathEventObjectives row={row} />
          </List>
        </ListDrawer>
      )}
    </ListRow>
  );
};

CartRow.propTypes = {
  row: MobxPropTypes.objectOrObservableObject.isRequired,
  hideEditButton: PropTypes.bool,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CartRow.defaultProps = {
  hideEditButton: false,
  showPlacesRemaining: true,
};

const LearningPathEventObjectives = ({ row }) => {
  if (!row.objectives) return null;

  return row.objectives.map(({ event }) => (
    <ListRow gridColumns="1fr 1fr 1fr" key={event.id}>
      <ListCell>
        <strong>{get(event, 'course.name', '-')}</strong>
      </ListCell>
      <ListCell>
        <span className="glyphicon glyphicon-map-marker" />{' '}
        {get(event, 'location.name', '-')}
      </ListCell>
      <ListCell>
        <span className="glyphicon glyphicon-calendar" />{' '}
        <span>
          {event.start
            ? formatDateLong(DateTime.fromISO(`${event.start}`))
            : '-'}
        </span>
      </ListCell>
    </ListRow>
  ));
};

const EditEventButton = ({ t, onClick }) => {
  return (
    <Button
      type="suppressed"
      label={
        <>
          <span className="show-mobile">{t('weblink:editCourse')}</span>
          <span
            className="show-desktop glyphicon glyphicon-pencil"
            aria-label="Edit Item"
          />
        </>
      }
      onClick={onClick}
    />
  );
};

EditEventButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

const RemoveItemButton = ({ t, onClick }) => {
  return (
    <Button
      type="suppressed"
      label={
        <>
          <span className="show-mobile">
            {t('weblink:delete')}
            <span className="sr-only">from Cart</span>
          </span>
          <span
            className="show-desktop glyphicon glyphicon-remove-circle"
            aria-label={`${t('weblink:delete')} from Cart`}
          />
        </>
      }
      onClick={onClick}
    />
  );
};

RemoveItemButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTranslation()(inject(STORE_CART)(observer(CartRow)));
