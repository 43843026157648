import React from 'react';
import PropTypes from 'prop-types';

const ItemBlock = ({ image, title, details, type }) => (
  <div className={type ? `ItemBlock ${type}` : 'ItemBlock'}>
    {image && (
      <div className="ItemBlock_image">
        <img src={image} alt="" />
      </div>
    )}
    <div className="ItemBlock_detail">
      <span className="ItemBlock_detail_title">{title}</span>
      {details && <span className="ItemBlock_detail_details">{details}</span>}
    </div>
  </div>
);

ItemBlock.defaultProps = {
  image: null,
  title: null,
  details: null,
  type: null,
};

ItemBlock.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  details: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  type: PropTypes.string,
};

export default ItemBlock;
