import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { StatusLabel } from '@administrate/piston-ux';
import { DateTime } from 'luxon';
import ItemBlock from './ItemBlock';
import withCurrency from '../../../containers/withCurrency';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../../constants';
import { formatDate } from '../../../utils/formatDateTime';

// TODO: Move this to a more generic place
export const getRemainingPlacesText = (remainingPlaces, t) =>
  `${t('weblink:placesRemaining')}: ${
    remainingPlaces === null ? t('weblink:unlimited') : remainingPlaces
  }`;

const EventItem = ({
  location,
  startDateTime,
  deliveryMethod,
  unitAmount,
  course,
  formatCurrency,
  remainingPlaces,
  imageUrl,
  isSoldOut,
  showPlacesRemaining,
  t,
}) => (
  <ItemBlock
    title={course}
    type="large"
    image={imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
    details={
      <>
        {location && (
          <span className="ItemBlock_detail__location">{`${location}, `}</span>
        )}
        <span className="ItemBlock_detail__date">
          {deliveryMethod === 'lms'
            ? t('weblink:uponEnrolment')
            : formatDate(startDateTime)}
        </span>
        <span className="ItemBlock_detail__price show-mobile">
          {`${t('weblink:price')}: ${formatCurrency(unitAmount)}`}
        </span>
        <div style={{ lineHeight: 1.5 }}>
          {showPlacesRemaining && (
            <span className="ItemBlock_detail__places">
              {getRemainingPlacesText(remainingPlaces, t)}
            </span>
          )}
          {isSoldOut && (
            <StatusLabel
              extraClass="sold-out-status-label"
              color="red"
              text={t('weblink:soldOut')}
            />
          )}
        </div>
      </>
    }
  />
);

EventItem.defaultProps = {
  location: null,
  imageUrl: CATALOG_ITEM_IMAGE_PLACEHOLDER,
  startDateTime: null,
  remainingPlaces: null,
  isSoldOut: false,
  showPlacesRemaining: true,
};

EventItem.propTypes = {
  location: PropTypes.string,
  imageUrl: PropTypes.string,
  startDateTime: PropTypes.instanceOf(DateTime),
  unitAmount: PropTypes.number.isRequired,
  course: PropTypes.string.isRequired,
  remainingPlaces: PropTypes.number,
  formatCurrency: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
  isSoldOut: PropTypes.bool,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withCurrency(EventItem));
