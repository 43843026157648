import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ItemBlock from './ItemBlock';
import withCurrency from '../../../containers/withCurrency';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../../constants';

const GiftVoucherItem = ({ unitAmount, formatCurrency, imageUrl, t }) => (
  <ItemBlock
    title={t('weblink:giftVoucher')}
    type="large"
    image={imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
    details={
      <span className="ItemBlock_detail__price show-mobile">
        {`${t('weblink:price')}: ${formatCurrency(unitAmount)}`}
      </span>
    }
  />
);

GiftVoucherItem.defaultProps = {
  imageUrl: CATALOG_ITEM_IMAGE_PLACEHOLDER,
};

GiftVoucherItem.propTypes = {
  imageUrl: PropTypes.string,
  unitAmount: PropTypes.number.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withCurrency(GiftVoucherItem));
