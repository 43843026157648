import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ItemBlock from './ItemBlock';
import withCurrency from '../../../containers/withCurrency';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../../constants';

import { getRemainingPlacesText } from './EventItem';

const PathItem = ({
  children,
  unitAmount,
  title,
  formatCurrency,
  imageUrl,
  remainingPlaces,
  showPlacesRemaining,
  t,
}) => (
  <ItemBlock
    title={title}
    type="large"
    image={imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
    details={
      <>
        {showPlacesRemaining && (
          <div>
            <span className="ItemBlock_detail__places">
              {getRemainingPlacesText(remainingPlaces, t)}
            </span>
          </div>
        )}
        <div>
          <span className="ItemBlock_detail__price show-mobile">
            {`${t('weblink:price')}: ${formatCurrency(unitAmount)}`}
          </span>
        </div>
        {children}
      </>
    }
  />
);

PathItem.defaultProps = {
  children: null,
  imageUrl: CATALOG_ITEM_IMAGE_PLACEHOLDER,
  remainingPlaces: null,
  showPlacesRemaining: true,
};

PathItem.propTypes = {
  children: PropTypes.node,
  unitAmount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  remainingPlaces: PropTypes.number,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withCurrency(PathItem));
