import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import {
  Button,
  Col,
  DateTimeInput,
  Input,
  List,
  ListCell,
  ListHeader,
  ListRow,
  Pagination,
  Row,
  Select,
  ListEmpty,
} from '@administrate/piston-ux';
import { FormConsumer } from '@administrate/piston-ux/lib/Form';
import { DateTime } from 'luxon';
import { useEventsFetcher } from './queryHelpers';
import { EventDateRange } from '../../EventDateRange';
import { WithCurrentTimeZone } from '../../../containers/withCurrentTimeZone';
import { useWebLinkQuery } from '../../../weblink-hooks';
import queries from '../../../queries';
import { formatDateWithTime } from '../../../utils/formatDateTime';

const hasUnlimitedPlaces = event =>
  event.remainingPlaces === null || event.remainingPlaces === undefined;

const _EventObjectiveSelector = observer(
  ({
    courseCode,
    learningPathId,
    onEventSelection,
    showRemainingPlacesFilter,
    showLocationFilter,
    t,
    currentTimeZone,
    locale,
    availableSeats,
    startDate,
    location,
    onEventSearchCompleted,
  }) => {
    const {
      loading,
      error,
      events,
      totalEvents,
      eventOffset,
      setEventOffset,
    } = useEventsFetcher(
      learningPathId,
      courseCode,
      location,
      startDate,
      availableSeats,
      currentTimeZone,
      onEventSearchCompleted,
    );

    const { data } = useWebLinkQuery(queries.location.getLocations);
    const locations = data && data.locations ? data.locations.edges : [];
    const locationNames = locations.map(({ node: { name, id } }) => ({
      label: name,
      value: id,
    }));

    const eventLimit = 10;
    const gridColumns = '2fr 2fr 1fr';
    return (
      <List>
        <div
          className="filters"
          role="search"
          aria-label={`${t('weblink:for')} ${courseCode}`}
        >
          <Row>
            {showLocationFilter && (
              <Col lg={4}>
                <Select
                  options={locationNames}
                  label={t('weblink:location')}
                  name="location"
                />
              </Col>
            )}
            <Col lg={4}>
              <DateTimeInput
                useBrowserLocale
                localeOverride={locale}
                type="date"
                label={t('weblink:startDate')}
                name="startDate"
                placeholder={t('weblink:today')}
              />
            </Col>
            <Col lg={4}>
              {showRemainingPlacesFilter && (
                <Input
                  name="availableSeats"
                  label={t('weblink:learnerNumbers')}
                  type="number"
                  min={1}
                />
              )}
            </Col>
          </Row>
        </div>
        <ListHeader
          gridColumns={`${gridColumns} 150px`}
          headings={[
            {
              title: t('weblink:location'),
            },

            {
              title: t('weblink:courseDates'),
            },
            {
              title: t('weblink:availableSeats'),
            },
          ]}
        />

        {!error && !loading && events.length > 0 && (
          <>
            {events.map(event => (
              <Fragment key={event.id}>
                <ListRow
                  gridColumns={gridColumns}
                  extra={
                    <FormConsumer>
                      {() => {
                        if (!event.registrationOpen) {
                          return (
                            <>
                              <small>{t('weblink:registrationOpensOn')}:</small>
                              <p>
                                {formatDateWithTime(
                                  DateTime.fromISO(event.registrationOpensAt),
                                )}
                              </p>
                            </>
                          );
                        }

                        return (
                          <Button
                            type="suppressed"
                            disabled={event.remainingPlaces === 0}
                            ariaLabel={
                              event.remainingPlaces === 0
                                ? t('weblink:eventNotAvailable')
                                : t('selectEvent')
                            }
                            label={t('weblink:select')}
                            onClick={() => {
                              onEventSelection(event);
                            }}
                          />
                        );
                      }}
                    </FormConsumer>
                  }
                  extraWidth={120}
                >
                  <ListCell label={t('weblink:location')}>
                    {event.location.name}
                  </ListCell>
                  <ListCell label={t('weblink:courseDates')}>
                    <EventDateRange event={event} />
                  </ListCell>
                  <ListCell label={t('weblink:availableSeats')}>
                    {hasUnlimitedPlaces(event)
                      ? 'Unlimited'
                      : event.remainingPlaces}
                  </ListCell>
                </ListRow>
              </Fragment>
            ))}
            {totalEvents > eventLimit && (
              <Pagination
                offset={eventOffset}
                totalRecords={totalEvents}
                limit={eventLimit}
                setOffset={setEventOffset}
                maxPagesDisplayed={3}
              />
            )}
          </>
        )}
        {loading && (
          <ListRow
            gridColumns={gridColumns}
            extra={
              <Button
                label={t('weblink:select')}
                onClick={() => []}
                disabled
                type="suppressed"
              />
            }
            extraWidth={120}
          >
            <ListCell label={t('weblink:location')} loading />
            <ListCell label={t('weblink:courseDates')} loading />
            <ListCell label={t('weblink:availableSeats')} loading />
          </ListRow>
        )}
        {!error && !loading && events.length === 0 && (
          <ListEmpty text={t('weblink:noCoursesAvailable')} />
        )}
        {error && <ListEmpty text={t('weblink:errorLoadingEventObjectives')} />}
      </List>
    );
  },
);

export const EventObjectiveSelector = WithCurrentTimeZone(
  _EventObjectiveSelector,
);
